import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()
  const refOfferListTable = ref(null)
  const refFilesListTable = ref(null)
  const refInsuredPartiesListTable = ref(null)
  const refPolisHolderListTable = ref(null)
  const refBenificiariesListTable = ref(null)

  // Insured Parties Table Handlers
  const benificiariesTableColumns = [
    { label: 'Naam', key: 'name', sortable: true },
    { label: 'Geboortedatum', key: 'birthDate', sortable: true },
    { label: 'Relatie omschrijving', key: 'relationDescription', sortable: true },
  ]

  // Insured Parties Table Handlers
  const polisHolderTableColumns = [
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'Documentstatus', key: 'documentStatus', sortable: false },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]

  // Insured Parties Table Handlers
  const insuredPartiesTableColumns = [
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'Documentstatus', key: 'documentStatus', sortable: false },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]

  // Table Handlers
  const tableFilesColumns = [
    { label: 'Naam', key: 'description', sortable: true },
    { label: 'Aanmaakdatum', key: 'file.timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Verzekernemer', key: 'policyHolderRelation', sortable: true },
    { label: 'Product', key: 'product', sortable: true },
    { label: 'Aangemaakt op', key: 'timestamp', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const tableInvoicesColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Verzekeringsnemer', key: 'order', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Bedrag', key: 'amount', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Aangemaakt op', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const tableReceiptsColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Verzekeringsnemer', key: 'order', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Bedrag', key: 'amount', sortable: true },
    { label: 'Aangemaakt op', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const tableColumnsProlongations = [
    { label: '#', key: 'id', sortable: false },
    { label: 'Product', key: 'product', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Premie', key: 'premium', sortable: true },
    { label: 'Verzekerd bedrag', key: 'insuredAmount', sortable: true },
    { label: 'Datum', key: 'timestamp', sortable: true },
  ]

  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const sortByStatus = ref(5)
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refOfferListTable.value ? refOfferListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchFilesData = () => {
    refFilesListTable.value.refresh()
  }

  const refetchData = () => {
    if (refOfferListTable && refOfferListTable.value) {
      refOfferListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, sortByStatus, searchQuery], () => {
    refetchData()
  })

  const showOverlay = ref(true)

  const fetchOrders = (ctx, callback) => {
    showOverlay.value = true
    store.dispatch('app-order/fetchOrders', {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: sortByStatus.value,
      q: searchQuery.value,
    })
      .then(response => {
        const { items, _meta } = response.data
        showOverlay.value = false
        callback(items)
        totalOrders.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 5) return 'info'
    if (status === 1 || status === 2) return 'info'
    if (status === 10) return 'success'
    if (status === 90 || status === 91 || status === 92) return 'danger'
    if (status === 93) return 'warning'
    return 'primary'
  }

  const resolveColorVariant = status => {
    if (status === 5) return '#a6a4b0'
    if (status === 1 || status === 2) return '#fdb813'
    if (status === 10) return '#28c76f'
    if (status === 90 || status === 91 || status === 92) return '#ea5455'
    if (status === 93) return '#ff9f43'
    return '#a6a4b0'
  }

  const resolveActivitieVariant = status => {
    if (status === 1) return { title: 'Offerte aangemaakt', icon: 'ShoppingCartIcon', variant: 'primary' }
    if (status === 2) return { title: 'Medisch gevalideerd', icon: 'ActivityIcon', variant: 'success' }
    if (status === 3) return { title: 'Polis berekening', icon: 'PercentIcon', variant: 'info' }
    if (status === 4) return { title: 'Offerte geaccepteerd', icon: 'ThumbsUpIcon', variant: 'success' }
    if (status === 5) return { title: 'Order gevalideerd', icon: 'StarIcon', variant: 'success' }
    if (status === 6) return { title: 'Order actief', icon: 'AwardIcon', variant: 'success' }
    if (status === 10) return { title: 'Offerte afgewezen', icon: 'ThumbsDownIcon', variant: 'secondary' }
    if (status === 11) return { title: 'Order afgewezen', icon: 'ThumbsDownIcon', variant: 'secondary' }
    if (status === 12) return { title: 'Offerte verlopen', icon: 'ThumbsDownIcon', variant: 'warning' }
    if (status === 30) return { title: 'Order document', icon: 'FilePlusIcon', variant: 'info' }
    if (status === 31) return { title: 'Offerte document', icon: 'FilePlusIcon', variant: 'info' }
    if (status === 32) return { title: 'Polis document', icon: 'FilePlusIcon', variant: 'info' }
    if (status === 40) return { title: 'Factuur aangemaakt', icon: 'CreditCardIcon', variant: 'info' }
    if (status === 41) return { title: 'Kwitantie aangemaakt', icon: 'CreditCardIcon', variant: 'info' }
    if (status === 70) return { title: 'Suppletie verwerkt', icon: 'EditIcon', variant: 'success' }
    return { title: '', icon: '', variant: '' }
  }

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveTypeVariant = status => {
    if (status === 1) return 'Particulier'
    if (status === 2) return 'Zakelijk'
    return ''
  }

  const resolveInvoiceTypeVariant = status => {
    if (status === 1) return { tooltip: 'Niet betaald', icon: 'CreditCardIcon', variant: 'warning' }
    if (status === 2) return { tooltip: 'Betaald', icon: 'CreditCardIcon', variant: 'success' }
    return { tooltip: 'Niet betaald', icon: 'CreditCardIcon', variant: 'warning' }
  }

  return {
    fetchOrders,
    tableColumns,
    tableFilesColumns,
    insuredPartiesTableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refOfferListTable,
    refFilesListTable,
    refInsuredPartiesListTable,
    refPolisHolderListTable,
    polisHolderTableColumns,
    refBenificiariesListTable,
    benificiariesTableColumns,
    tableColumnsProlongations,
    resolveActivitieVariant,

    sortByStatus,
    showOverlay,

    resolveInvoiceStatusVariantAndIcon,

    refetchData,
    refetchFilesData,
    resolveStatusVariant,
    resolveColorVariant,
    resolveTypeVariant,

    tableInvoicesColumns,
    resolveInvoiceTypeVariant,
    tableReceiptsColumns,
    searchQuery,
  }
}
