<template>
  <b-tabs
    v-model="tabIndex"
    lazy
  >
    <b-tab
      v-if="userData.role !== 20"
      ref="tabOffer"
      title="Offertes"
      content-class="mt-1"
      show
      lazy
      @click="updateStatus('5')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="offer">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                      <b-button
                        v-if="$can('create', 'order')"
                        variant="primary"
                        :to="{ name: 'apps-order-flow-checkout'}"
                      >
                        Aanmaken
                      </b-button>
                    </b-col>

                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>

                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :id="`order-view-action-row-${data.item.id}`"
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`order-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                      <b-tooltip
                        :target="`order-view-action-row-${data.item.id}`"
                        placement="top"
                      >
                        <p class="mb-0">
                          Aanpassen
                        </p>
                      </b-tooltip>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role !== 20"
      ref="actionRequired"
      title="Inbehandeling"
      content-class="mt-1"
      lazy
      @click="updateStatus('1,2')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="pendingOffers">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                      <b-button
                        v-if="$can('create', 'order')"
                        variant="primary"
                        :to="{ name: 'apps-order-flow-checkout'}"
                      >
                        Aanmaken
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>

                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role === 20"
      title="Medische validatie"
      content-class="mt-1"
      lazy
      @click="updateStatus('1')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="medicalOffers">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                      <b-button
                        v-if="$can('create', 'order')"
                        variant="primary"
                        :to="{ name: 'apps-order-flow-checkout'}"
                      >
                        Aanmaken
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>

                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role !== 20"
      title="Actief"
      content-class="mt-1"
      lazy
      @click="updateStatus('10,93')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="activeOffers">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                      <b-button
                        v-if="$can('create', 'order')"
                        variant="primary"
                        :to="{ name: 'apps-order-flow-checkout'}"
                      >
                        Aanmaken
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>

                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role !== 20"
      title="Geannuleerd"
      content-class="mt-1"
      lazy
      @click="updateStatus('90,91')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="declinedOffers">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                      <b-button
                        v-if="$can('create', 'order')"
                        variant="primary"
                        :to="{ name: 'apps-order-flow-checkout'}"
                      >
                        Aanmaken
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>
                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role !== 20"
      title="Royement afgehandeld"
      content-class="mt-1"
      lazy
      @click="updateStatus('92')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="disbarmentOffers">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>
                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
    <b-tab
      v-if="userData.role !== 20"
      title="Claim afgehandeld"
      content-class="mt-1"
      lazy
      @click="updateStatus('120')"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <section id="claims">
          <b-row>
            <b-col cols="12">
              <!-- Table Container Card -->
              <b-card
                no-body
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Records</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                    </b-col>
                    <b-col
                      class="d-flex align-items-center justify-content-end"
                      cols="12"
                      md="6"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />
                    </b-col>
                  </b-row>

                </div>
                <b-table
                  ref="refOfferListTable"
                  :items="fetchOrders"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  :sort-by-status.sync="sortByStatus"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      class="font-weight-bold"
                      :style="`color:${resolveColorVariant(data.item.status)}`"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>
                  <!-- Column: Client -->
                  <template #cell(policyHolderRelation)="data">
                    <b-media
                      v-if="data.value.type === 1"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.item.policyHolderRelation))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ getFullName(data.item.policyHolderRelation) }}
                      </span>
                      <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                    </b-media>
                    <b-media
                      v-if="data.value.type === 2"
                      vertical-align="center"
                    >
                      <template #aside>
                        <b-avatar
                          size="32"
                          src=""
                          :text="avatarText(getFullName(data.value.companyName))"
                          :style="`background:${resolveColorVariant(data.item.status)}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.value.companyName }}
                      </span>
                      <small class="text-muted">{{ getFullName(data.item.policyHolderRelation) }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    {{ statusParser(data.item.status) }}
                  </template>

                  <!-- Column: Id -->
                  <template #cell(product)="data">
                    {{ data.item.product.name }}
                  </template>

                  <template #cell(timestamp)="data">
                    <span class="text-nowrap">
                      {{ formatDateTime(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon
                          :id="`invoice-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                          :variant="resolveStatusVariant(data.item.status)"
                        />
                      </b-link>
                    </div>
                  </template>

                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                        dataMeta.of
                      }} records zichtbaar</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BFormInput, BLink, BMedia, BOverlay, BPagination, BRow, BTab, BTable, BTabs, BTooltip,
} from 'bootstrap-vue'
import { avatarText, kFormatter } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useOrderList from '@/views/pages/order/useOrderList'
import orderStoreModule from '@/views/pages/order/orderStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BLink,
    BPagination,
    BAvatar,
    BMedia,
    BTabs,
    BTab,
    BTooltip,
    BOverlay,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      userData: {},
      tabIndex: 0,
    }
  },
  watch: {
    userData: {
      handler() {
        if (this.userData.role && this.userData.role === 20) {
          this.updateStatus('1,2')
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  created() {
    if (!this.$route.params.id || String(this.$route.params.id) === '5') {
      this.tabIndex = 0
      this.sortByStatus = '5'
    }

    if (String(this.$route.params.id) === '1,2') {
      this.tabIndex = 1
      this.sortByStatus = '1,2'
    }

    if (String(this.$route.params.id) === '10,93') {
      this.tabIndex = 2
      this.sortByStatus = '10,93'
    }

    if (String(this.$route.params.id) === '90,91,92') {
      this.tabIndex = 3
      this.sortByStatus = '90,91,92'
    }
  },
  methods: {
    statusParser(status) {
      switch (status) {
        case 1:
          return 'Medische Check'
        case 2:
          return 'Accepteer Order'
        case 5:
          return 'Accepteer offerte'
        case 10:
          return 'Actief'
        case 80:
          return 'Verlengd'
        case 90:
          return 'Offerte Verlopen'
        case 91:
          return 'Afgewezen'
        case 92:
          return 'Royement geaccepteerd'
        case 93:
          return 'Royement aangevraagd'
        case 110:
          return 'Claim aangevraagd'
        case 111:
          return 'Claim geaccepteerd'
        case 112:
          return 'Claim geaccepteerd onder voorwaarden'
        case 113:
          return 'Claim gedeeltelijk geaccepteerd'
        case 114:
          return 'Claim afgewezen'
        case 120:
          return 'Claim afgehandeld'
        default:
          return 'Onbekend'
      }
    },
    kFormatter,
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    updateStatus(status) {
      if (String(this.$route.params.id) !== status) {
        this.$router.replace({ path: `/order/overview/${status}` })
      }
      this.sortByStatus = status
    },
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const actionRequired = ref(null)

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,
      sortByStatus,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      resolveStatusVariant,
      resolveColorVariant,
      showOverlay,
      resolveTypeVariant,
      searchQuery,
    } = useOrderList()

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,
      sortByStatus,
      refetchData,
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      resolveStatusVariant,
      resolveColorVariant,
      showOverlay,
      resolveTypeVariant,
      actionRequired,
      formatDateTime,
      searchQuery,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
